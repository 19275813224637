.div{
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
    /* border: 1px solid  red; */
  
}
.left{
    margin-right: 100px;
}