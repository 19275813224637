.div{
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    /* border: 1px solid  red; */
}
.div2{
    display: flex;
    height: 10px;
    justify-content: center;
    align-items: center;
    /* border: 1px solid  red; */
    padding-bottom: 100px;
}
.beian{
    padding-left: 16px;
    color: #777;
    cursor: pointer;
}